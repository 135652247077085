import { InputField, PasswordInputField, PhoneField } from '@components/HookForm'
import { LoadingButton } from '@components/Loaders'
import * as ROUTE from '@constants/routes'
import { phoneValidator } from '@helpers/validators'
import { useAppSelector } from '@hooks'
import { authSelector } from '@store/selector'
import classnames from 'classnames'
import { join, prop } from 'ramda'
import { useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { CustomInput, Form, FormGroup, Label } from 'reactstrap'

export function LoginForm({ handleSubmit, onSubmit, register, errors, control, shouldUpdatePass, watch, unregister }) {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const { formatMessage: msg } = useIntl()
  const auth = useAppSelector(authSelector)
  const isAdmission = window.location.hostname === 'admission.isft.uz'
  const isTransfer = window.location.hostname === 'transfer.isft.uz'
  const usernameValue = watch('username')
  const phoneValue = watch('phone')
  const hasUsernameValue = useMemo(() => usernameValue && usernameValue.trim().length, [usernameValue])
  const hasPhoneValue = useMemo(() => {
    if (phoneValue && phoneValue.trim().length) {
      const digits = phoneValue.match(/([0-9])/g)
      return join('', digits).substr(3).length
    }
  }, [phoneValue])
  const loginLoading = prop('loading', auth)

  function handleInputChange(value) {
    setUsername(value)
  }

  function handlePasswordChange(value) {
    setPassword(value)
  }

  return (
    <Form className='auth-login-form mt-2' onSubmit={handleSubmit(onSubmit)}>
      {!shouldUpdatePass ? (
        <>
          {isAdmission || isTransfer ? null : hasPhoneValue ? null : (
            <Controller
              render={({ field }) => (
                <InputField
                  {...field}
                  placeholder={msg({ id: 'User ID' })}
                  id='username'
                  register={register}
                  shouldColWrap={false}
                  onUnmount={() => {
                    unregister('username')
                  }}
                  handleChange={handleInputChange}
                  error={prop('username', errors)}
                  className={classnames({ 'is-invalid': errors['username'] })}
                />
              )}
              name='username'
              control={control}
              defaultValue={username}
              rules={{
                required: true,
                validate: v => {
                  if (!v) {
                    return 'This field is required'
                  }
                },
              }}
            />
          )}

          {hasPhoneValue || hasUsernameValue ? null : (
            <div className='divider'>
              <div className='divider-text text-uppercase'>{msg({ id: 'or' })}</div>
            </div>
          )}

          {hasUsernameValue ? null : (
            <Controller
              render={({ field }) => (
                <PhoneField
                  {...field}
                  register={register}
                  onUnmount={() => {
                    unregister('phone')
                  }}
                  placeholder={msg({ id: 'phone' })}
                  error={prop('phone', errors)}
                  invalid={prop('phone', errors)}
                  mask='+\9\9\8\(99) 999 99 99'
                />
              )}
              rules={{
                required: true,
                validate: phoneValidator,
              }}
              name='phone'
              control={control}
            />
          )}
        </>
      ) : null}
      {hasUsernameValue || hasPhoneValue ? (
        <FormGroup>
          <div className='d-flex justify-content-between'>
            <Label className='form-label' for='login-password'>
              {msg({ id: 'password' })}
            </Label>
            <Link to={ROUTE.RESET_PASSWORD_REQUEST}>
              <small>{msg({ id: 'Forgot Password?' })}</small>
            </Link>
          </div>
          <Controller
            render={({ field }) => (
              <PasswordInputField
                {...field}
                id='login-password'
                handleChange={handlePasswordChange}
                register={register}
                onUnmount={() => {
                  unregister('password')
                }}
                error={prop('password', errors)}
              />
            )}
            name='password'
            control={control}
            defaultValue={password}
            rules={{
              required: true,
              validate: value => value !== '',
            }}
          />
        </FormGroup>
      ) : null}

      <FormGroup>
        <CustomInput
          type='checkbox'
          className='custom-control-Primary'
          id='remember-me'
          label={msg({ id: 'rememberMe' })}
        />
      </FormGroup>
      <LoadingButton
        loading={loginLoading}
        spinnerSize='sm'
        type='submit'
        onClick={handleSubmit(onSubmit)}
        color='primary'
        block
      >
        {msg({ id: 'signIn' })}
      </LoadingButton>
    </Form>
  )
}
