import { lazy } from 'react'
import { ACCOUNTS } from '../constants/route'
import * as roles from '@constants/userRoles'
import { REGISTRAR_HEAD } from '@constants/userRoles'

export const AccountRoutes = [
  {
    path: ACCOUNTS,
    component: lazy(() => import('../views/Account')),
    meta: {
      access: [roles.SUPER_ADMIN, REGISTRAR_HEAD, roles.HR_MANAGER, roles.HR_HEAD, roles.ADMIN],
    },
  },
]
