import { useLatest } from '@hooks'
import { produce } from 'immer'
import { nanoid } from 'nanoid'
import { useLayoutEffect, useMemo, useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFunction = (arg: any): arg is (arg: any) => any => typeof arg === 'function'

type Subscriber<T> = (value: T) => void
export type Signal<T> = T extends ReturnType<typeof createSignal<T>> ? T : never

export type Target<T> = {
  value: T
  getValue: () => T
  subscribe: (subscriber: Subscriber<T>) => string
  pureSubscribe: (subscriber: Subscriber<T>) => () => void
  unsubscribe: (id: string) => boolean
  signalName?: string
  (draft?: (value: T) => T | void): void
}

export const createSignal = <T>(
  defaultValue: T,
  name?: string,
  onUpdate?: (props: { target?: Target<T>; key?: string | symbol; newValue?: T }) => void
) => {
  const subscribers = new Map<string, Subscriber<T>>()
  const target: Target<T> = (callback?: (value: T) => T | void) => {
    if (callback) {
      const produced = produce(target.getValue(), callback)
      target.value = produced
      onUpdate && onUpdate({ target, newValue: produced })
      subscribers.forEach(subscriber => {
        subscriber(target.getValue())
      })
      return produced
    }
  }

  target.value = defaultValue
  target.getValue = () => target.value
  target.pureSubscribe = listener => {
    const id = nanoid()
    subscribers.set(id, listener)
    return () => {
      subscribers.delete(id)
    }
  }
  target.subscribe = subscriber => {
    const id = nanoid()
    subscribers.set(id, subscriber)
    return id
  }
  target.unsubscribe = (id: string) => {
    return subscribers.delete(id)
  }
  target.signalName = name

  const signal = new Proxy(target, {
    get(target, key) {
      if (key === 'pureSubscribe') return target.pureSubscribe
      if (key === 'subscribe') return target.subscribe
      if (key === 'getValue') return target.getValue
      if (key === 'value' || key === 'subscribe' || key === 'unsubscribe') return target[key]
    },
    set(target, key, newValue: T) {
      onUpdate && onUpdate({ target, key, newValue })
      if (key !== 'value') return true
      if (typeof newValue !== 'function' && Object.is(target[key], newValue)) return true

      target[key] = newValue
      subscribers.forEach(subscriber => {
        subscriber(newValue)
      })
      return true
    },
  })

  return signal
}

export const useSignal = <T>(
  defaultValue: T,
  name?: string,
  onUpdate?: (props: { target?: Target<T>; key?: string | symbol; newValue?: T }) => void
) => {
  const latestDefaultValue = useLatest(defaultValue)
  const signal = useMemo(() => createSignal(latestDefaultValue.current, name, onUpdate), [])

  return signal
}

export type Updater<T, G = T> = (value: T) => G | ((value: T) => G)

export const useSignalConsumer = <T, G = T>(signal: Target<T>, updater?: Updater<T, G>) => {
  const isUpdater = (callback: G | ((value: T) => G)): callback is (value: T) => G => {
    return typeof callback === 'function'
  }

  const [state, setState] = useState<T | G>(
    updater
      ? () => {
          const returned = updater(signal.value)
          if (isFunction(returned)) {
            return returned(signal.value)
          }
          return returned
        }
      : () => signal.value
  )
  const subscriber = useLatest(
    updater
      ? (value: T) => {
          return setState(prevState => {
            const returned = updater(value)
            if (isUpdater(returned)) {
              return returned(prevState as T)
            }
            return returned
          })
        }
      : setState
  )
  useLayoutEffect(() => {
    const id = signal.subscribe(subscriber.current)

    return () => {
      signal.unsubscribe(id)
    }
  }, [])

  return { state, signal }
}
