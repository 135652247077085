import { STUDENT_CV, STUDENT_CV_CREATE } from '@constants/routes'
import { BACHELOR_STUDENT, DEAN, SUPER_ADMIN } from '@constants/userRoles'
import { lazy } from 'react'

export const StudentCVRoutes = [
  {
    path: STUDENT_CV,
    component: lazy(() => import('../views/StudentCVList')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, DEAN],
    },
  },
  {
    path: STUDENT_CV_CREATE,
    component: lazy(() => import('../views/StudentCVCreate')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, BACHELOR_STUDENT, DEAN],
    },
  },
]
