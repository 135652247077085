import { SUPER_ADMIN, STUDENTS_DEPARTMENT } from '@constants/userRoles'
import { lazy } from 'react'

import { ATTENDANCE_REASON } from '../constants/route'

export const AttendanceReasonRoutes = [
  {
    path: ATTENDANCE_REASON,
    component: lazy(() => import('../views/AttendaceReason')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, STUDENTS_DEPARTMENT],
    },
  },
]
