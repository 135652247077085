import { lazy } from 'react'

import * as roles from '@constants/userRoles'
import { COURSE_CAMPAIGN } from '../constants/route'

export const CourseCampaignRoutes = [
  {
    path: COURSE_CAMPAIGN,
    component: lazy(() => import('../../activate/views/CourseCampaign')),
    meta: {
      access: [
        roles.SUPER_ADMIN,
        roles.ADMIN,
        roles.QUALITY_ASSURANCE,
        roles.QUALITY_ASSURANCE_HEAD,
        roles.MODERATOR_VIEWER,
        roles.REGISTRAR_OFFICER,
      ],
    },
  },
]
