export const SUPER_ADMIN = 'SUPER_ADMIN'
export const ADMIN = 'ADMIN'
export const DIRECTOR = 'DIRECTOR'
export const BACHELOR_STUDENT = 'BACHELOR_STUDENT'
export const MASTER_STUDENT = 'MASTER_STUDENT'
export const STAFF = 'STAFF'
export const GUEST_USER = 'GUEST_USER'
export const SYSTEM_MODERATOR = 'SYSTEM_MODERATOR'
export const QUALITY_ASSURANCE = 'QUALITY_ASSURANCE'
export const QUALITY_ASSURANCE_HEAD = 'QUALITY_ASSURANCE_HEAD'
export const TEACHER = 'TEACHER'
export const MODULE_LEADER = 'MODULE_LEADER'
export const ACADEMIC_MENTOR = 'ACADEMIC_MENTOR'
export const REGISTRAR_HEAD = 'REGISTRAR_HEAD'
export const HR_HEAD = 'HR_HEAD'
export const HR_MANAGER = 'HR_MANAGER'
export const REGISTRAR_OFFICER = 'REGISTRAR_OFFICER'
export const REGISTRAR_HELPER = 'REGISTRAR_HELPER'
export const PROGRAMME_MANAGER = 'PROGRAMME_MANAGER'
export const AR_COMMITTEE = 'AR_COMMITTEE'
export const EXTRACURRICULAR_ACTIVITY_MANAGER = 'EXTRACURRICULAR_ACTIVITY_MANAGER'
export const MODERATOR_VIEWER = 'MODERATOR_VIEWER'
export const ADMISSION_VIEWER = 'ADMISSION_VIEWER'
export const TEACHING_AND_LEARNING_OFFICER = 'TEACHING_AND_LEARNING_OFFICER'
export const MS_APPLICANT = 'MS_APPLICANT'
export const MS_STUDENT = 'MS_STUDENT'
export const SHORT_TERM_COURSE_LISTENER = 'SHORT_TERM_COURSE_LISTENER'
export const LIBRARY_MODERATOR = 'LIBRARY_MODERATOR'
export const TEST_STUDENT = 'TEST_STUDENT'
export const DEMO_ADMIN = 'DEMO_ADMIN'
export const ADMISSION_MODERATOR = 'ADMISSION_MODERATOR'
export const ADMISSION_APPLICANT = 'ADMISSION_APPLICANT'
export const DEAN = 'DEAN'
export const MARKETING = 'MARKETING'
export const ACCOUNTING_HEAD = 'ACCOUNTING_HEAD'
export const COURSE_LEADER = 'COURSE_LEADER'
export const STUDENTS_DEPARTMENT = 'STUDENTS_DEPARTMENT'
export const AMO_CRM_OPERATOR = 'AMO_CRM_OPERATOR'
export const STUDENT_VIEWER = 'STUDENT_VIEWER'
export const EXAM_HEAD = 'EXAM_HEAD'
export const ARCHIVIST = 'ARCHIVIST'
export const ADMISSION_ARCHIVE_TASHKENT = 'ADMISSION_ARCHIVE_TASHKENT'
export const ADMISSION_ARCHIVE_SAMARKAND = 'ADMISSION_ARCHIVE_SAMARKAND'
export const AMO_CRM_OPERATOR_ADMISSION = 'AMO_CRM_OPERATOR_ADMISSION'
export const ADMISSION_APPOINTMENT_TEACHER = 'ADMISSION_APPOINTMENT_TEACHER'
