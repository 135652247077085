import {
  BACHELOR_STUDENT,
  GUEST_USER,
  MASTER_STUDENT,
  STAFF,
  SUPER_ADMIN,
  TEST_STUDENT,
} from '@src/constants/userRoles'
import { roleConstants } from '@src/hooks/useCheckRoles'
import { any } from 'ramda'
import { lazy } from 'react'

import { DOCUMENT_DETAILS, DOCUMENT_MANAGEMENT_INCOMING, DOCUMENT_MANAGEMENT_OUTGOING } from '../constants/route'

export const DocumentManagement = [
  {
    path: DOCUMENT_MANAGEMENT_INCOMING,
    component: lazy(() => import('../views/IncomingDocuments')),
    exact: true,
    meta: {
      access: (roles: roleConstants[]) => {
        return !any(role => [GUEST_USER, BACHELOR_STUDENT, MASTER_STUDENT, TEST_STUDENT].includes(role), roles)
      },
    },
  },
  {
    path: DOCUMENT_MANAGEMENT_OUTGOING,
    component: lazy(() => import('../views/OutgoingDocuments')),
    exact: true,
    meta: {
      access: (roles: roleConstants[]) => {
        return !any(role => [GUEST_USER, BACHELOR_STUDENT, MASTER_STUDENT, TEST_STUDENT].includes(role), roles)
      },
    },
  },
  {
    path: DOCUMENT_DETAILS,
    component: lazy(() => import('../views/DocumentDetails')),
    exact: true,
    meta: {
      access: (roles: roleConstants[]) => {
        return !any(role => [GUEST_USER, BACHELOR_STUDENT, MASTER_STUDENT, TEST_STUDENT].includes(role), roles)
      },
    },
  },
]
