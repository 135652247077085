import { lazy } from 'react'
import { ROLES } from '../constants/route'
import * as roles from '@constants/userRoles'

export const RolesRoutes = [
  {
    path: ROLES,
    component: lazy(() => import('../views/Roles')),
    meta: {
      access: [roles.SUPER_ADMIN],
    },
  },
]
