import { selectThemeColors } from '@utils'
import classNames from 'classnames'
import { dissoc, equals, isNil, map, prop, type } from 'ramda'
import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import AsyncSelect from 'react-select/async'
import { FormGroup, FormText, Label } from 'reactstrap'

import { IndicatorsContainer, Menu, SelectContainer } from '../SelectField/components'

const customStyles = {
  control: provided => {
    return {
      ...provided,
      border: '1px solid #e2e7f1',
    }
  },
  multiValue: provided => ({
    ...provided,
    margin: '0.2rem',
  }),
  menu: provided => ({
    ...provided,
    zIndex: '10',
  }),
  input: provided => ({
    ...provided,
    color: 'inherit',
  }),
  container: provided => ({
    ...provided,
  }),
}

const AsyncSelectField = React.forwardRef((props, ref) => {
  const {
    options = [],
    label,
    placeholder,
    isMulti,
    customError,
    disabled,
    className,
    row,
    autoFocus,
    colClassName,
    isLoading = false,
    style,
    name,
    menuPosition,
    menuIsOpen = false,
    formGroupStyles = {},
    getOptions,

    handleChange = () => {},
    error,
    menuPortalTarget,
    value = null,
    onChange,
    onFocus = () => {},
    handleFocus = () => {},
    isClearable = false,
    id,
    normalize,
    required = false,
    menuPlacement = 'auto',
    onBeforeChange,
    customComponents = {},
  } = props

  const wrapperRef = useRef(null)
  const selectRef = useRef(null)

  const change = (value, rest) => {
    const { name } = rest
    if (normalize) {
      const normalized = equals(type(value), 'Array')
        ? map(item => prop('value', item))(value || [])
        : prop('value', value)
      setValue(normalized, name)
    } else {
      setValue(value, name)
    }
  }

  function setValue(value, name) {
    onBeforeChange && onBeforeChange(value, name)
    onChange(value)
    handleChange(value, name)
  }

  const focus = () => {
    onFocus()
    handleFocus()
  }

  function menuPortal(base) {
    const rect = wrapperRef.current.getBoundingClientRect()
    return {
      ...base,
      zIndex: 9999,
      position: 'absolute',
      top: rect.top + window.scrollY + rect.height,
    }
  }

  function closeMenu() {
    selectRef.current.blur()
  }

  function openMenu() {
    selectRef.current.focus()
  }

  const loadOptions = (inputValue, callback) => {
    getOptions(inputValue, callback)
  }

  const htmlId = id ? { id } : {}

  return (
    <FormGroup row={Boolean(row)} style={formGroupStyles}>
      {label && (
        <Label>
          {required ? (
            <span>
              {label}&nbsp;<span style={{ color: 'red' }}>*</span>
            </span>
          ) : (
            label
          )}
        </Label>
      )}
      <div ref={wrapperRef}>
        <AsyncSelect
          cacheOptions
          {...dissoc('menuIsOpen', props)}
          name={name}
          id={id}
          styles={{
            ...customStyles,
            menuPortal,
          }}
          loadOptions={loadOptions}
          className={classNames('react-select', error ? `react-select-error is-invalid ${className}` : `${className}`)}
          classNamePrefix='select'
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          value={value || null}
          components={{ SelectContainer, Menu, IndicatorsContainer, ...customComponents }}
          menuShouldScrollIntoView={false}
          onChange={change}
          // options={options}
          onFocus={focus}
          isDisabled={disabled}
          autoFocus={autoFocus}
          isLoading={isLoading}
          ref={selectRef}
          {...htmlId}
          isClearable={isClearable}
          captureMenuScroll
          defaultOptions={options}
          theme={selectThemeColors}
          menuPortalTarget={!isNil(menuPortalTarget) ? menuPortalTarget : document.body}
          menuPlacement={menuPlacement}
          closeMenu={closeMenu}
          openMenu={openMenu}
          // menuIsOpen={menuOpen}
        />
      </div>
      {error && (
        <FormText color='danger'>
          <FormattedMessage id='form_required' />
        </FormText>
      )}
    </FormGroup>
  )
})

export default AsyncSelectField
