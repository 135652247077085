import { ADMIN, STUDENTS_DEPARTMENT, SUPER_ADMIN } from '@constants/userRoles'
import { lazy } from 'react'

import { COURSE_TRANSFER } from '../constants/route'

export const CourseTransferRoutes = [
  {
    path: COURSE_TRANSFER,
    component: lazy(() => import('../views/CourseTransfer')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, ADMIN, STUDENTS_DEPARTMENT],
    },
  },
]