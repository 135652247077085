import { lazy } from 'react'

import {
  MODERATOR_VIEWER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_OFFICER,
  SUPER_ADMIN,
  ADMIN,
} from '@constants/userRoles'
import { COURSE_CREATE } from '../constants/route'

export const CourseCreateRoutes = [
  {
    path: COURSE_CREATE,
    component: lazy(() => import('../views/CourseCreate')),
    meta: {
      access: [SUPER_ADMIN, QUALITY_ASSURANCE, QUALITY_ASSURANCE_HEAD, MODERATOR_VIEWER, REGISTRAR_OFFICER, ADMIN],
    },
  },
]
