import { useAppDispatch } from '@src/hooks'
import { useIntl } from '@src/hooks/useIntl'
import { Target, useSignal } from '@src/hooks/useSignal'
import { studentCVChangeStatus, types } from '@src/redux/actions'
import { isEmpty } from 'ramda'
import { Controller, useForm } from 'react-hook-form'
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { useSyncExternalStore } from 'use-sync-external-store/shim'

import { SelectField } from './HookForm'

type Props = {
  signal: Target<{ state: boolean }>
}

export default function ChangeSvStatusModal({ signal }: Props) {
  const { formatMessage: t } = useIntl()
  const dispatch = useAppDispatch()
  const { state } = useSyncExternalStore(signal.pureSubscribe, signal.getValue)
  const { control, handleSubmit, reset } = useForm<{ status: { label: string; value: string } }>()
  const optionsSignal = useSignal<{ loading: boolean; options: { label: string; value: string }[] }>({
    loading: false,
    options: [],
  })

  function onSubmit(data: { status: { label: string; value: string } }) {
    dispatch(
      studentCVChangeStatus({
        status: data.status.value as
          | 'DOES_NOT_NEED_JOB'
          | 'EMPLOYED'
          | 'NEEDS_JOB'
          | 'NEEDS_JOB_WITH_EXACT_SALARY'
          | 'OPEN_TO_OFFERS'
          | 'UPDATE_REQUIRED',
      })
    )
      .unwrap()
      .then(() => {
        close()
      })
  }

  function onOpened() {
    optionsSignal(draft => {
      draft.loading = true
    })

    dispatch(
      types({
        variable: 'need_for_job_status',
      })
    )
      .unwrap()
      .then(res => {
        if (res && !isEmpty(res)) {
          optionsSignal(draft => {
            draft.options = res.filter(item => item.value !== 'UPDATE_REQUIRED')
          })
        }
      })
      .finally(() => {
        optionsSignal(draft => {
          draft.loading = false
        })
      })
  }

  function close() {
    signal(draft => {
      draft.state = false
    })
  }

  return (
    <Modal isOpen={state} toggle={close} onOpened={onOpened} onClosed={() => reset({ status: undefined })}>
      <ModalHeader>Change CV status</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Controller
                control={control}
                name='status'
                render={({ field }) => (
                  <SelectField {...field} signal={optionsSignal} label={t({ id: 'changeStatus' })} />
                )}
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='success' onClick={handleSubmit(onSubmit)}>
          {t({ id: 'changeStatus' })}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
