/* eslint-disable @typescript-eslint/no-explicit-any */
import flatpickr from 'flatpickr'
import moment from 'moment'
import { head, map } from 'ramda'
import { ReactNode, forwardRef, useEffect, useRef } from 'react'
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr'
import { FieldError } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { FormGroup, FormText, Label } from 'reactstrap'

import '@core/styles/react/libs/flatpickr/flatpickr.scss'

type ChangeArgType = (arg: string | number | (number | string)[] | Date[] | null) => void

type PropsType = Omit<DateTimePickerProps, 'onChange'> & {
  data_enable_time?: boolean
  label?: ReactNode
  error?: FieldError
  formatDate?: (value: string) => string
  isTimestamp?: boolean
  inputClassName?: string
  handleChange?: ChangeArgType
  onReadyCallback?: flatpickr.Options.Hook
  onChange: ChangeArgType
}

const FlatpickrField = forwardRef((props: PropsType) => {
  const {
    label,
    data_enable_time,
    className = '',
    disabled = false,
    options,
    onChange,
    error,
    required,
    defaultValue,
    placeholder,
    value,
    name,
    formatDate,
    id,
    isTimestamp = false,
    handleChange,
    inputClassName = '',
    onReadyCallback,
  } = props
  const inputRef = useRef<HTMLInputElement>()

  useEffect(() => {
    if (inputRef.current) {
      error ? inputRef.current.classList.add('is-invalid') : inputRef.current.classList.remove('is-invalid')
      inputClassName && inputRef.current.classList.add(inputClassName)
    }
  }, [inputRef.current, error])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.disabled = disabled
    }
  }, [inputRef.current, disabled])

  const change: flatpickr.Options.Hook = (dates, currentDateString) => {
    if (formatDate) {
      const formatted = formatDate(currentDateString)
      onChange(formatted)
      handleChange && handleChange(formatted)
    } else {
      const isRangeDate = options?.mode === 'range' || options?.mode === 'multiple'
      const date = head(dates)
      if (isTimestamp) {
        const epoch = isRangeDate
          ? map((date: string) => (date ? +moment(date).format('x') : ''))(dates as unknown as string[])
          : date
          ? +moment(date).format('x')
          : null
        onChange(epoch)
        handleChange && handleChange(epoch)
      } else {
        if (isRangeDate) {
          onChange(dates)
          handleChange && handleChange(dates)
        } else {
          onChange(+moment(date).format('x'))
          handleChange && handleChange(+moment(date).format('x'))
        }
      }
    }
  }

  const editedOptions = {
    ...options,
    locale: {
      firstDayOfWeek: 1,
    },
  }

  const htmlId = id ? { id } : {}
  const htmlFor = id ? { for: id } : {}

  const onReady: { onReady: flatpickr.Options.Hook; onOpen: flatpickr.Options.Hook } = {
    onReady(arg1, arg2, fp) {
      onReadyCallback && onReadyCallback(arg1, arg2, fp)
      const altInput = fp?.altInput

      if (altInput) {
        altInput.readOnly = false
        inputRef.current = altInput
      }
    },
    onOpen(arg1, arg2, fp) {
      const instance = fp
      if (instance) {
        const calendarContainer = instance.calendarContainer
        if (calendarContainer) {
          const timeEnabled = data_enable_time || options?.enableTime
          timeEnabled
            ? !Array.from(calendarContainer.classList).includes('hasTime') && calendarContainer.classList.add('hasTime')
            : calendarContainer.classList.remove('hasTime')
        }
      }
    },
  }
  return (
    <FormGroup>
      {label && (
        <Label {...htmlFor}>
          {required ? (
            <span>
              {label}&nbsp;<span style={{ color: 'red' }}>*</span>
            </span>
          ) : (
            label
          )}
        </Label>
      )}

      <Flatpickr
        data-enable-time={data_enable_time}
        name={name}
        value={defaultValue || value}
        options={editedOptions}
        className={`${error ? 'is-invalid' : ''} ${className}`}
        {...onReady}
        onChange={change}
        {...htmlId}
        placeholder={placeholder}
      />

      {error && (
        <FormText color='danger'>
          <FormattedMessage id='form_required' />
        </FormText>
      )}
    </FormGroup>
  )
})

export default FlatpickrField
