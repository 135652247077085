import * as ROLES from '@constants/userRoles'
import { lazy } from 'react'

import {
  FULL_TIME_APPLICANTS,
  GUEST_USERS,
  GUEST_USER_INFO,
  MASTER_APPLICANTS,
  SECONDARY_SPECIALIZATION_APPLICANTS,
  TRANSFER_APPLICANTS,
} from '../constants/route'

export const GuestUsersRoutes = [
  {
    path: GUEST_USERS,
    component: lazy(() => import('../views/GuestList')),
    exact: true,
    meta: {
      access: [
        ROLES.SUPER_ADMIN,
        ROLES.ADMISSION_MODERATOR,
        ROLES.ADMISSION_VIEWER,
        ROLES.AMO_CRM_OPERATOR_ADMISSION,
        ROLES.ADMISSION_APPOINTMENT_TEACHER,
        ROLES.ADMISSION_ARCHIVE_TASHKENT,
        ROLES.ADMISSION_ARCHIVE_SAMARKAND,
      ],
    },
  },
  {
    path: FULL_TIME_APPLICANTS,
    component: lazy(() => import('../views/GuestFullTimeApplicantList')),
    exact: true,
    meta: {
      access: [
        ROLES.SUPER_ADMIN,
        ROLES.ADMISSION_MODERATOR,
        ROLES.ADMISSION_VIEWER,
        ROLES.AMO_CRM_OPERATOR_ADMISSION,
        ROLES.ADMISSION_APPOINTMENT_TEACHER,
      ],
    },
  },
  {
    path: GUEST_USER_INFO,
    component: lazy(() => import('../views/GuestFullProfile/GuestUserFullProfile')),
    exact: true,
    meta: {
      access: [
        ROLES.SUPER_ADMIN,
        ROLES.ADMISSION_MODERATOR,
        ROLES.AMO_CRM_OPERATOR_ADMISSION,
        ROLES.ADMISSION_APPOINTMENT_TEACHER,
        ROLES.ADMISSION_ARCHIVE_TASHKENT,
        ROLES.ADMISSION_ARCHIVE_SAMARKAND,
      ],
    },
  },
  {
    path: TRANSFER_APPLICANTS,
    component: lazy(() => import('../views/GuestList')),
    exact: true,
    meta: {
      access: [
        ROLES.SUPER_ADMIN,
        ROLES.ADMISSION_MODERATOR,
        ROLES.AMO_CRM_OPERATOR_ADMISSION,
        ROLES.ADMISSION_APPOINTMENT_TEACHER,
        ROLES.ADMISSION_ARCHIVE_TASHKENT,
        ROLES.ADMISSION_ARCHIVE_SAMARKAND,
      ],
    },
  },
  {
    path: SECONDARY_SPECIALIZATION_APPLICANTS,
    component: lazy(() => import('../views/GuestList')),
    exact: true,
    meta: {
      access: [
        ROLES.SUPER_ADMIN,
        ROLES.ADMISSION_MODERATOR,
        ROLES.AMO_CRM_OPERATOR_ADMISSION,
        ROLES.ADMISSION_APPOINTMENT_TEACHER,
        ROLES.ADMISSION_ARCHIVE_TASHKENT,
        ROLES.ADMISSION_ARCHIVE_SAMARKAND,
      ],
    },
  },
  {
    path: MASTER_APPLICANTS,
    component: lazy(() => import('../views/GuestList')),
    exact: true,
    meta: {
      access: [
        ROLES.SUPER_ADMIN,
        ROLES.ADMISSION_MODERATOR,
        ROLES.AMO_CRM_OPERATOR_ADMISSION,
        ROLES.ADMISSION_APPOINTMENT_TEACHER,
        ROLES.ADMISSION_ARCHIVE_TASHKENT,
        ROLES.ADMISSION_ARCHIVE_SAMARKAND,
      ],
    },
  },
]
