import { RootState } from '@src/redux/store'
import { prop } from 'ramda'
import { createSelector } from 'reselect'

import {
  ATTENDANCE_REASON,
  ATTENDANCE_REASON_ALL,
  ATTENDANCE_REASON_CREATE,
  ATTENDANCE_REASON_DELETE,
  ATTENDANCE_REASON_LIST,
  GLOBAL_STATE,
} from '../constants/actionTypes'

const attendanceReasonSelectors = (state: RootState) => prop(GLOBAL_STATE, state)

export const attendanceReasonSelector = createSelector(attendanceReasonSelectors, prop(ATTENDANCE_REASON))

export const attendanceReasonAllSelector = createSelector(attendanceReasonSelectors, prop(ATTENDANCE_REASON_ALL))

export const attendanceReasonCreateSelector = createSelector(attendanceReasonSelectors, prop(ATTENDANCE_REASON_CREATE))

export const attendanceReasonDeleteSelector = createSelector(attendanceReasonSelectors, prop(ATTENDANCE_REASON_DELETE))

export const attendanceReasonListSelector = createSelector(attendanceReasonSelectors, prop(ATTENDANCE_REASON_LIST))
