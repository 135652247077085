import { lazy } from 'react'

import { ACADEMIC_YEARS } from '../constants/route'
import * as roles from '@constants/userRoles'
import { MODERATOR_VIEWER, QUALITY_ASSURANCE, QUALITY_ASSURANCE_HEAD, REGISTRAR_OFFICER, ADMIN } from '@constants/userRoles'

export const AcademicYearsRoutes = [
  {
    path: ACADEMIC_YEARS,
    component: lazy(() => import('../views/AcademicYears')),
    meta: {
      access: [roles.SUPER_ADMIN, QUALITY_ASSURANCE, QUALITY_ASSURANCE_HEAD, MODERATOR_VIEWER, REGISTRAR_OFFICER, ADMIN],
    },
  },
]
