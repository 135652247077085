export const GLOBAL_STATE = 'Student'

export const STUDENT = 'STUDENT'
export const STUDENT_SEND_SMS = 'STUDENT_SEND_SMS'
export const STUDENT_EXPORT_DATA = 'STUDENT_EXPORT_DATA'
export const STUDENT_TRANSFER = 'STUDENT_TRANSFER'
export const STUDENT_PROFILE = 'STUDENT_PROFILE'
export const STUDENT_PROFILE_UPDATE = 'STUDENT_PROFILE_UPDATE'
export const STUDENT_STATUS_UPDATE = 'STUDENT_STATUS_UPDATE'
export const STUDENT_LIST = 'STUDENT_LIST'
export const STUDENT_DELETE = 'STUDENT_DELETE'
export const STUDENT_ALL = 'STUDENT_ALL'
export const STUDENT_GUARD_CREATE = 'STUDENT_GUARD_CREATE'
export const STUDENT_GUARD_LIST = 'STUDENT_GUARD_LIST'
export const STUDENT_USERNAME = 'STUDENT_USERNAME'
export const STUDENT_DOCUMENT = 'STUDENT_DOCUMENT'
export const STUDENT_PERSONAL_INFO = 'STUDENT_PERSONAL_INFO'
export const STUDENT_CONTACT_INFO = 'STUDENT_CONTACT_INFO'
export const STUDENT_DEBIT_DETAIL_CREATE = 'STUDENT_DEBIT_DETAIL_CREATE'
export const STUDENT_DEBIT_DETAIL_DELETE = 'STUDENT_DEBIT_DETAIL_DELETE'
export const GENERATE_TRANSCRIPT = 'GENERATE_TRANSCRIPT'
export const STUDENT_ACHIEVEMENT_RECORDS = 'STUDENT_ACHIEVEMENT_RECORDS'
export const STUDENT_CALL_LETTER_DELETE = 'STUDENT_CALL_LETTER_DELETE'
export const STUDENT_RESUME_STUDY = 'STUDENT_RESUME_STUDY'
export const STUDENT_MARK_UPDATE = 'STUDENT_MARK_UPDATE'
