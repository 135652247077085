import { equals, pathOr, propOr } from 'ramda'
import { forwardRef, useEffect } from 'react'
import InputMask from 'react-input-mask'
import { FormattedMessage } from 'react-intl'
import { FormGroup, FormText, Label } from 'reactstrap'

const PhoneField = forwardRef((props, ref) => {
  const {
    label,
    className = '',
    id = '',
    active,
    autoFocus,
    value,
    onChange,
    disabled,
    handleChange = () => {},
    onBlur,
    error,
    placeholder = '(___) __-___-____',
    formGroupProps = {},
    invalid,
    mask = '(99) 999-99-99',
    type = 'tel',
    maskChar = '_',
    beforeMaskedValueChange,
    formatChars,
    onUnmount,
  } = props

  useEffect(() => {
    return () => {
      onUnmount && onUnmount()
    }
  }, [])

  const htmlId = id ? { id } : {}
  const htmlFor = id ? { for: id } : {}

  function change(e) {
    const value = pathOr('', ['target', 'value'], e)
    if (value) {
      onChange(`${value}`)
      handleChange(`${value}`)
    }
  }

  const beforeMaskedValueChangeProp = beforeMaskedValueChange ? { beforeMaskedValueChange } : {}

  return (
    <FormGroup {...formGroupProps}>
      {label && <Label {...htmlFor}>{label}</Label>}

      <InputMask
        value={value}
        className={`form-control ${error || invalid ? 'is-invalid' : ''} ${className}`}
        onChange={change}
        onBlur={onBlur}
        active={active}
        {...htmlId}
        type={type}
        maskChar={maskChar}
        autoComplete='new-password'
        mask={mask}
        disabled={disabled}
        readOnly={disabled}
        placeholder={placeholder}
        autoFocus={autoFocus}
        inputRef={ref}
        {...beforeMaskedValueChangeProp}
        formatChars={formatChars}
        invalid={error || invalid ? 'true' : undefined}
      />

      {error && (
        // <FormText color='danger'>{propOr('This field is', 'message', error)}</FormText>
        <FormText color='danger'>
          <FormattedMessage
            id={equals(error?.type, 'required') ? 'form_required' : propOr('form_required', 'message', error)}
          />
        </FormText>
      )}
    </FormGroup>
  )
})

export default PhoneField
