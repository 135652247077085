// ** Dropdowns Imports
import { useAppSelector, useCheckRoles, useUniversalModal } from '@hooks'
import UserProfileResetPasswordModal from '@module/Auth/components/modals/UserProfileResetPasswordModal'
import { GUEST_USER } from '@src/constants/userRoles'
import { authUserInfo } from '@store/selector'
import { UserSettingsContext } from '@utility/context/UserSettings'
import { isEmpty, propOr } from 'ramda'
import { Fragment, useContext } from 'react'
// ** Third Party Components
import { Menu, Moon, Sun } from 'react-feather'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { DropdownItem, DropdownMenu, DropdownToggle, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap'
import styled from 'styled-components'

import IntlDropdown from './IntlDropdown'
import UserDropdown from './UserDropdown'

const StyledDropdownMenu = styled(DropdownMenu)`
  top: 40px !important;
`

const StyledDropdownItem = styled(DropdownItem)`
  width: 100% !important;
  &:hover {
    color: ${({ $isDark }) => ($isDark ? 'var(--white)' : '#6e6b7b')} !important;
    background-color: ${({ $isDark }) => ($isDark ? '#161d31' : '#f1f1f1')} !important;
  }
`

const NavbarUser = props => {
  // ** Props
  const { skin, setSkin, setMenuVisibility, isMenuHidden } = props

  const history = useHistory()
  const { formatMessage: msg } = useIntl()
  const { education_form_id, institution_id, setEduForm, setInstitution } = useContext(UserSettingsContext)
  const userInfo = useAppSelector(authUserInfo)
  const { amI } = useCheckRoles()

  const isDark = skin === 'dark'

  const [modal, openModal, closeModal] = useUniversalModal()

  const renderInstitutionOptions = () =>
    propOr([], 'institutions', userInfo).map(item => (
      <StyledDropdownItem
        key={item.value}
        $isDark={isDark}
        active={institution_id === item.value}
        onClick={() => {
          history.replace({
            search: '',
          })
          setInstitution(item)
        }}
      >
        {propOr('', 'label', item)}
      </StyledDropdownItem>
    ))

  const renderEduFormOptions = () =>
    propOr([], 'education_forms', userInfo).map(item => (
      <StyledDropdownItem
        key={item.value}
        $isDark={isDark}
        active={education_form_id === item.value}
        onClick={() => {
          history.replace({
            search: '',
          })
          setEduForm(item)
        }}
      >
        {propOr('', 'label', item)}
      </StyledDropdownItem>
    ))

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return <Sun className='ficon' onClick={() => setSkin('light')} />
    } else {
      return <Moon className='ficon' onClick={() => setSkin('dark')} />
    }
  }
  return (
    <Fragment>
      <ul className='navbar-nav d-xl-none d-flex align-items-center'>
        {!isMenuHidden ? (
          <NavItem className='mobile-menu mr-auto'>
            <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => setMenuVisibility(true)}>
              <Menu className='ficon' />
            </NavLink>
          </NavItem>
        ) : null}
      </ul>
      <div className='bookmark-wrapper d-flex align-items-center'>
        <NavItem className='d-none d-block'>
          <NavLink className='nav-link-style'>
            <ThemeToggler />
          </NavLink>
        </NavItem>
      </div>
      <div className='d-none d-md-flex edu-add-info'>
        {userInfo?.institutions && !isEmpty(userInfo.institutions) ? (
          <UncontrolledDropdown tag='div'>
            <DropdownToggle color='flat-default-blue' caret>
              {propOr(msg({ id: 'branch' }), 'label', userInfo?.selected_institution)}
            </DropdownToggle>
            <StyledDropdownMenu>{renderInstitutionOptions()}</StyledDropdownMenu>
          </UncontrolledDropdown>
        ) : null}

        {userInfo?.education_forms && !isEmpty(userInfo.education_forms) ? (
          <UncontrolledDropdown tag='div'>
            <DropdownToggle color='flat-secondary' caret>
              {propOr(msg({ id: 'EducationForm' }), 'label', userInfo?.selected_education_form)}
            </DropdownToggle>
            <StyledDropdownMenu>{renderEduFormOptions()}</StyledDropdownMenu>
          </UncontrolledDropdown>
        ) : null}
      </div>

      <ul className='nav navbar-nav align-items-center ml-auto'>
        {!amI(GUEST_USER) ? <IntlDropdown liStyles={{ marginRight: '0.5rem' }} /> : null}

        <UserDropdown openModal={openModal} />
      </ul>

      <UserProfileResetPasswordModal isOpen={modal.state} item={modal.item} close={closeModal} />
    </Fragment>
  )
}
export default NavbarUser
