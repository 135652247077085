import { ApiFunc, getInstance, getNotAuthInstance } from '@helpers/httpClient'

import * as API from '../constants/api'

const authorizedInstance = getInstance()
const unauthorizedInstance = getNotAuthInstance()

export const userInfo: ApiFunc<'/user/me/info', 'get'> = data => authorizedInstance.get(API.USER_INFO, { params: data })

export const userActionLog: ApiFunc<'/user/action/log', 'post'> = data =>
  authorizedInstance.post(API.USER_ACTION_LOG, data)

export const signIn: ApiFunc<'/signin', 'post'> = data => unauthorizedInstance.post(API.SIGN_IN, data)

export const sendSms: ApiFunc<'/sms/send', 'post'> = data => unauthorizedInstance.post(API.SMS_SEND, data)

export const signUp: ApiFunc<'/signup', 'post'> = data => unauthorizedInstance.post(API.SIGN_UP, data)

export const userUpdatePassword: ApiFunc<'/user/own/password', 'put'> = data =>
  authorizedInstance.put(API.USER_UPDATE_OWN_PASSWORD, data)

export const resetPasswordRequest: ApiFunc<'/action_verification_email/reset/password/request', 'post'> = data =>
  authorizedInstance.post(API.RESET_PASSWORD_REQUEST, data)

export const resetPassword: ApiFunc<'/action_verification_email/reset/password', 'post'> = data =>
  authorizedInstance.post(API.RESET_PASSWORD, data)

export const verifyEmailWithToken: ApiFunc<'/verify-email', 'post'> = data =>
  unauthorizedInstance.post(API.VERIFY_EMAIl, data)

export const verifyEmailWithCode: ApiFunc<'/verify-email', 'post'> = data =>
  authorizedInstance.post(API.VERIFY_EMAIl, data)

export const verifyEmail: ApiFunc<'/verify-email', 'post'> = data => authorizedInstance.post(API.VERIFY_EMAIl, data)

export const saveUserSettings: ApiFunc<'/user/settings/', 'post'> = data =>
  authorizedInstance.post(API.USER_SETTINGS, data)

export const checkGuestUser: ApiFunc<`${typeof API.CHECK_GUEST_USER}`, 'post'> = data =>
  unauthorizedInstance.post(API.CHECK_GUEST_USER, data)

export const userProfileResetPassword: ApiFunc<'/user/profile/password', 'put'> = data =>
  authorizedInstance.put(API.USER_PROFILE_RESET_PASSWORD, data)

export const userInfoHistory: ApiFunc<'/user/info_history/', 'get'> = data =>
  authorizedInstance.get(API.USER_INFO_HISTORY, { params: data })
