import {
  DEAN,
  PROGRAMME_MANAGER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_HEAD,
  REGISTRAR_HELPER,
  REGISTRAR_OFFICER,
  SUPER_ADMIN,
  ADMIN,
  COURSE_LEADER,
  STUDENTS_DEPARTMENT
} from '@constants/userRoles'
import { lazy } from 'react'

import { GROUPING, GROUP_ASSIGN } from '../constants/route'

export const GroupingRoutes = [
  {
    path: GROUPING,
    component: lazy(() => import('../views/Grouping')),
    exact: true,
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_HELPER,
        PROGRAMME_MANAGER,
        DEAN,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        COURSE_LEADER,
        STUDENTS_DEPARTMENT,
      ],
    },
  },
  {
    path: GROUP_ASSIGN,
    component: lazy(() => import('../views/GroupAssign')),
    exact: true,
    meta: {
      access: [SUPER_ADMIN, ADMIN, REGISTRAR_HEAD, REGISTRAR_OFFICER, REGISTRAR_HELPER, PROGRAMME_MANAGER, COURSE_LEADER],
    },
  },
]
