import { lazy } from 'react'
import { CALENDAR } from '../constants/route'
import {
  BACHELOR_STUDENT,
  MODERATOR_VIEWER,
  MODULE_LEADER,
  PROGRAMME_MANAGER,
  QUALITY_ASSURANCE,
  QUALITY_ASSURANCE_HEAD,
  REGISTRAR_HEAD,
  REGISTRAR_OFFICER,
  REGISTRAR_HELPER,
  SUPER_ADMIN,
  ADMIN,
  TEACHER,
  TEACHING_AND_LEARNING_OFFICER,
  TEST_STUDENT,
  MASTER_STUDENT,
} from '@constants/userRoles'

export const CalendarRoutes = [
  {
    path: CALENDAR,
    component: lazy(() => import('../views/Calendar')),
    meta: {
      access: [
        SUPER_ADMIN,
        ADMIN,
        BACHELOR_STUDENT,
        MASTER_STUDENT,
        TEST_STUDENT,
        QUALITY_ASSURANCE,
        QUALITY_ASSURANCE_HEAD,
        REGISTRAR_HEAD,
        REGISTRAR_OFFICER,
        REGISTRAR_HELPER,
        PROGRAMME_MANAGER,
        TEACHING_AND_LEARNING_OFFICER,
        TEACHER,
        MODULE_LEADER,
        MODERATOR_VIEWER,
      ],
    },
  },
]
